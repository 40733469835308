import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import customData from './clientsData.json';
import siteConfig from './config.json';
import 'react-slideshow-image/dist/styles.css'

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faArrowRight, faMagnifyingGlass, faAngleRight, faAngleLeft, faTableCellsLarge, faLink } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { Slide } from 'react-slideshow-image';

const clients = customData
const config = siteConfig;

function App() {

  const [category, setCategory] = useState('hepsi');
  const [filterclients, setFilteredClients] = useState([]);

  useEffect(() => {
    category === 'hepsi' ? setFilteredClients(clients) : setFilteredClients(clients.filter(client => client.category.includes(category)))
  }, [category])

  const [searchText, setSearchText] = useState("");

  const handleOnChange = (event) => { setSearchText(event.target.value); };

  const filteredList = filterclients.filter( ({ name}) => name.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route exact path='/'
            render={() =>
              <div>
                <header>
                  <nav>
                    <div className='row w-100'>
                    <span className='header-logo col-xl-2 col-md-4 col-12'>
                      <a className='d-flex align-items-end p-md-0 py-3' href={config.website}>
                        <img className="Xlogo" src={require('../src/logos/' + config.logo1)} alt={config.name} width="150px" />
                      </a>
                    </span>

                    <ul className='category-list mb-0 col-xl col-md-8 col-12'>
                      <CategoryBtn name="hepsi" handleSetCategory={setCategory} />
                      <CategoryBtn name="e-ticaret" handleSetCategory={setCategory} />
                      <CategoryBtn name="kurumsal" handleSetCategory={setCategory} />
                      <CategoryBtn name="gıda" handleSetCategory={setCategory} />
                      <CategoryBtn name="endüstriyel" handleSetCategory={setCategory} />
                      <CategoryBtn name="yapı" handleSetCategory={setCategory} />
                    </ul>

                    <span className='search-input col-xl-2 col-12 ms-md-auto pt-xl-0 pt-md-4 py-md-0 py-3 ms-md-1 ms-3'>
                      <form className='form-group'>
                        <span className='search-icon'> <FontAwesomeIcon icon={faMagnifyingGlass} /> </span>
                        <input className='form-control pl-4' value={searchText} onChange={handleOnChange} type="text" placeholder='Ara...'/>
                      </form>
                    </span>
                    </div>
                  </nav>
                </header>

                <ul className='clients-list'>
                  {filteredList.map(client =>
                    <li id={client.name} className={"clients" + " " + client.category+ " " + client.disable} key={client.id}>
                      <img src={`/images/${client.coverImg}`} alt={client.name} loading="lazy" />
                      <div className='client-names'>
                        <h2>{client.name}</h2>
                      </div>

                      <div className='href-btns'>
                        <Link to={`/detay/${client.name}`}>
                          <FontAwesomeIcon icon={faInfo} />
                        </Link>

                        <a href={client.href} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                      </div>
                    </li>)}
                </ul>
              </div>
            } />

          <Route path='/detay/:firma' component={Detail}
            render={renderProps => {
              return <Detail {...renderProps} client={renderProps} />;
            }} />
        </Switch>
        <footer>
          <div className='part-1'>
            <a href={ config.website }>
              <img src={require('../src/logos/'+config.logo2)} />
            </a>
            <p>{ config.slogan }</p>
          </div>

          <div className='part-2'>
            <p>© 2024 <a href={ config.website }>{ config.copy }</a></p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

const Detail = props => {
  const client = clients.find(client => client.name === props.match.params.firma)

  var beforeEl = beforeClient();
  var afterEl = afterClient();

  function beforeClient() {
    if (Number(client.id) > 1) {
      const beforeClientIndex = Number(client.id) - 1;
      return clients[beforeClientIndex].name;
    } else {
      return clients[0].name;
    }
  }

  function afterClient() {
    if (Number(client.id) < Number(clients.length) - 1) {
      const afterClientIndex = Number(client.id) + 1;
      return clients[afterClientIndex].name;
    } else {
      return clients[clients.length - 1].name;
    }
  }

  var otherClients = new Array();

  for (let i = 0; i < 3; i++) {
    otherClients.push(clients[Math.floor(Math.random() * clients.length)]);
  }

  const slideImages = [];

  const clientCategory = (editCategory) => {
    return editCategory.category.replace(' ', ' / ')
  };

  const imgListLenght = Object.values(client.imageNames);

  imgListLenght.forEach(el => {
    slideImages.push("/images/" + el)
  });

  const Slideshow = () => {
    if (slideImages.length > 1) {
      return ( <div className='detail-img col-8'>
        <Slide easing="ease">
          {slideImages.map((each, index) => { return <div className="each-slide" key={'each-slide-' + index}> <img key={index} style={{ width: "100%" }} loading="lazy" src={each} /> </div>  })}
        </Slide> </div> )
    } else {
      return  <div className='detail-img col-8'> <img key={1} style={{ width: "100%" }} loading="lazy" src={slideImages[0]}/> </div>
    }
  };

  return (
    <div className='p-5 project-detail-page'>
      <div className='container'>
        <header className='mb-5'>
          <a href={ config.website }>
            <img src={require('./logos/'+config.logo3)} />
          </a>
        </header>

        <div className='prev-next d-flex justify-content-between w-100 my-3 '>
          <Link id='prev' to={"/detay/" + beforeEl}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <h6>Önceki Proje</h6>
          </Link>

          <Link to={`/`}>
            <FontAwesomeIcon icon={faTableCellsLarge} />
          </Link>

          <Link id='next' to={"/detay/" + afterEl}>
            <h6>Sonraki Proje</h6>
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>

        <div className='project-detail d-flex flex-row col-12'>
          <div className='detail-info col-4'>
            <h1>
              {`${client.name}`}
              <a className='p-2 fa-xs' href={`${client.href}`} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLink}/></a> 
            </h1>
            <h4 className='kategori'>{clientCategory(client)}</h4>
            {Object.keys(client).includes("company") && `${client.company}` !== "" ? (<div> <h3><b>Firma:</b> {`${client.company}`}</h3> </div>) : false}

            {Object.keys(client).includes("date") && `${client.date}` !== "" ? (<div><h3><b>Proje Yılı:</b> {`${client.date}`}</h3></div>) : false}

            {Object.keys(client).includes("explanation") && `${client.explanation}` !== "" ? (<div> <div className='subtitle'><h3>Açıklama</h3></div> <p>{`${client.explanation}`}</p> </div>) : false}

            {Object.keys(client).includes("properties") && `${client.properties}` !== "" ? (<div> <div className='subtitle'><h3>Sistem Modüllleri</h3></div> <p>{`${client.explanation}`}</p> </div>) : false}
          </div>
          <Slideshow />
        </div>

        <div className='other-projects mt-5'>
          <div className='other-project-title d-flex flex-row justify-content-between align-items-center pb-1 mb-4 border-bottom'>
            <h2>Diğer Projeler</h2>
          </div>
          <Row xs={1} md={3} className="g-3">
            {otherClients.map((el) => (
              <Col>
                <Link to={`/detay/${el.name}`}>
                  <Card>
                    <Card.Img variant="top" src={`/images/${el.coverImg}`} />
                    <Card.Body>
                      <Card.Title>
                        <h5>{`${el.name}`}</h5>
                      </Card.Title>
                      <Card.Text>
                        <h4 className='kategori'>{clientCategory(el)}</h4>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>)
}

const CategoryBtn = ({ name, handleSetCategory, }) => {
  return <li className={name + " " + "filter-link" + " " + (name === "hepsi" ? "active" : "")} id={name} onClick={(e) => (handleSetActive(e), handleSetCategory(name))}> {name} </li>
};

function handleSetActive(e) {
  var filterLinkActive = document.getElementById(e.target.id);
  var filterLink = document.querySelectorAll(".filter-link")
  filterLink.forEach((el) =>
    el.classList.remove("active")
  )
  filterLinkActive.classList.add("active");
}

export default App;
